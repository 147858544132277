import Swiper, {Navigation} from "swiper";

class FeatureSwiper {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.swiper = null;
    this.initSwiper();
  }

  initSwiper() {
    this.swiper = new Swiper(this.element, {
      allowTouchMove: true,
      allowClick: true,
      slidesPerView: "auto",
      slidesPerGroup: 1,
      spaceBetween: 20,
      breakpoints: {
        601: {
          slidesPerView: 2,
          slidesPerGroup: 1,
          spaceBetween: 30
        },
        992: {
          slidesPerView: 3,
          slidesPerGroup: 1,
          spaceBetween: 40
        },
      },
      modules: [Navigation],
      navigation: {
        prevEl: this.element.querySelector(".swiper-button.prev"),
        nextEl: this.element.querySelector(".swiper-button.next"),
      }
    });
  }
}

export default FeatureSwiper;
