class Accordion {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError('Invalid element');
    }

    this.accordion = element;

    this.initEvents();
  }

  initEvents() {
    this.accordion.querySelectorAll(".accordion__item").forEach(detail => {
      detail.addEventListener("click", event => {
        this.collapseOthers(detail);
      });
    });
  }

  collapseOthers(selectedItem) {
    if (this.accordion.querySelector('details') !== null) {
      this.accordion.querySelectorAll('details').forEach((el) => {
        if (el === selectedItem)
          return true;

        el.open = false;
      })
    }
  }
}

export default Accordion;
