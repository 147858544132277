/* eslint-disable max-len */
const isInViewport = (el) => {
  if (!el)
    return false;

  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    (rect.bottom - 20) <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

class Scroll {
  easing = (t) => t * t;

  getOffsetTop = (element) => {
    const box = element.getBoundingClientRect();
    const docElem = document.documentElement;

    return box.top + window.pageYOffset - docElem.clientTop;
  };

  scroll(destination, duration = 200) {
    const start = window.scrollY;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : this.getOffsetTop(destination);
    const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) - 90;
    const easingChoice = this.easing;
    const direction = (start < destinationOffsetToScroll) ? 'down' : 'up';

    if (!('requestAnimationFrame' in window)) {
      window.scroll(0, (destinationOffsetToScroll > 0) ? destinationOffsetToScroll : 0);
      return;
    }

    function scroll() {
      const now = 'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, ((now - startTime) / duration));
      const timeFunction = easingChoice(time);
      const footerIsInView = isInViewport(document.querySelector("#footer"));
      window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

      if (
        (direction === 'down' && (window.scrollY >= (destinationOffsetToScroll - 50) || window.scrollY === 0)) ||
        (direction === 'up' && (window.scrollY <= (destinationOffsetToScroll) || window.scrollY === 0)) ||
        footerIsInView
      ) {
        window.dispatchEvent(new Event('animated-scroll-complete'));
        return;
      }

      requestAnimationFrame(scroll);
    }

    setTimeout(() => {
      scroll();
    }, 1);
  }
}

export default Scroll;
