class TabbedFeatures {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.buttons = Array.from(this.element.querySelectorAll('.tabs__buttons a'));
    this.tabs = Array.from(this.element.querySelectorAll('.tabs__tab'));
    this.fill = this.element.querySelector('.tabs__fill');

    if (!this.buttons)
      throw new TypeError("[TabbedFeatures] No buttons found");

    if (!this.tabs)
      throw new TypeError("[TabbedFeatures] No tabs found");

    this.initStartingState();
    this.initEvents();
  }

  initStartingState() {
    this.moveFill(this.buttons[0]);
    this.showInitialTab();
  }

  initEvents() {
    this.buttons.forEach(button => {
      button.addEventListener("click", this.changeTab.bind(this));
    });

    window.addEventListener("hashchange", () => {
      if (window.location.hash) {
        this.changeViaHash();

        window.scrollTo({
          top: this.element.closest('.section').offsetTop,
          left: 0,
          behavior: 'smooth'
        });
      }
    });
  }

  changeTab(event) {
    event.preventDefault();

    const el = event.currentTarget;
    const selectedId = el.getAttribute("href").replace("#", "");
    const selectedTab = this.tabs.find(ele => ele.id === selectedId);

    this.moveFill(el);
    this.scrollToActiveTab(el);
    this.showTab(selectedTab)
  }

  changeViaHash() {
    const hashId = window.location.hash.replace("#", "");
    const found = this.tabs.find((tab) => tab.id === hashId);
    const button = this.buttons.find((tab) => tab.getAttribute("href") === window.location.hash);

    this.moveFill(button);
    this.scrollToActiveTab(button);
    this.showTab(found);
  }

  moveFill(el) {
    if (!el)
      return false;

    const left = el.offsetLeft;
    const width = el.offsetWidth;

    if (this.fill) {
      this.fill.style.left = `${left}px`;
      this.fill.style.width = `${width}px`;
    }

    this.buttons.forEach(button => button.classList.remove('active'));
    el.classList.add('active');
  }

  scrollToActiveTab(elem) {
    if (!elem)
      return false;

    elem.closest('.tabs__buttons').scroll({
      left: elem.offsetLeft - 20,
      top: 0,
      behavior: 'smooth'
    });
  }

  showInitialTab() {
    if (window.location.hash) {
      this.changeViaHash();

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);

      return true;
    }

    return this.showTab(this.tabs[0]);
  }

  showTab(el) {
    if (!el)
      return false;

    this.tabs.forEach(el => el.classList.remove('active'));
    el.classList.add('active');
  }
}

export default TabbedFeatures;
