import Swiper, {Navigation} from 'swiper';
import Card from "./Card";

class CardSwiper {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.section = this.element.closest(".section");
    this.swiperEl = this.element.querySelector(".swiper");
    this.swiper = null;
    this.initSwiper();
  }

  initSwiper() {
    this.swiper = new Swiper(this.swiperEl, {
      allowTouchMove: true,
      modules: [Navigation],
      allowClick: true,
      slidesPerView: "auto",
      spaceBetween: 20,
      navigation: {
        nextEl: this.section.querySelector(".swiper-button.next"),
        prevEl: this.section.querySelector(".swiper-button.prev"),
      },
    });
  }
}

export default CardSwiper;
