import {gsap} from "gsap";

class CommunitiesHeader {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.stage = this.element.querySelector(".header__stage");
    this.data = JSON.parse(this.element.dataset.elements ?? "false");
    this.currentIds = [];
    this.count = 4;

    if (this.data) {
      this.createWrapper();
      this.createInitialElements();
    } else {
      console.warn("[CommunitiesHeader] Empty element array");
    }
  }

  createWrapper() {
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("header__elements");
    (this.stage ?? this.element).insertAdjacentElement("beforeend", this.wrapper);

    if (this.wrapper) {
      gsap.timeline({
        scrollTrigger: {
          trigger: this.element,
          start: "-65 top",
          end: "+=1000",
          scrub: 1,
          // markers: true
        }
      }).to(this.wrapper, {
        scale: 1.3,
        opacity: .4
      });
    }
  }

  delay(delayInMS) {
    return new Promise(resolve => setTimeout(resolve, delayInMS));
  }

  createInitialElements() {
    let max = (this.data.length ?? 4) / 2;

    if (max > 8) {
      max = 8;
    }

    const sliced = this.data.slice(0, max);

    sliced.forEach((dataItem) => {
      const element = this.createElement(dataItem);
      this.wrapper.insertAdjacentElement("afterbegin", element);
      this.currentIds.push(dataItem.id);
    });
  }

  createElement(dataItem) {
    const element = document.createElement("div");
    element.setAttribute("class", dataItem.classes);
    element.setAttribute("data-id", dataItem.id);

    element.innerHTML = `
      <img src="${dataItem.imgSrc ?? ""}" class="anim-el__image" alt="" />
      <div class="anim-el__text">
        <svg class="anim-el__thumb"><use xlink:href="#icon--thumbs-up" /></svg>
        <span>£30</span>
      </div>
    `;

    element.addEventListener("animationiteration", this.animationEvent.bind(this));
    element.addEventListener("animationend", this.animationEvent.bind(this));

    return element;
  }

  animationEvent(animationEvent) {
    animationEvent.target.style.animationPlayState = "paused";

    const opacity = parseFloat(window.getComputedStyle(animationEvent.target).opacity);
    const vagueOpacityCheck = opacity < 0.5;

    if (vagueOpacityCheck) {
      const id = parseFloat(animationEvent.target.getAttribute("data-id"));

      const possible = this.data.filter((dataItem) => !this.currentIds.includes(dataItem.id));
      console.log({possible, test: this.currentIds});

      if (possible.length) {
        this.currentIds = this.currentIds.filter((currIds) => currIds === id);

        const selectedElement = possible[Math.floor(Math.random() * possible.length)];

        animationEvent.target.setAttribute("class", selectedElement.classes);
        animationEvent.target.setAttribute("data-id", selectedElement.id);

        animationEvent.target.innerHTML = `
          <img src="${selectedElement.imgSrc ?? ""}" class="anim-el__image" alt="" />
          <div class="anim-el__text">
            <svg class="anim-el__thumb"><use xlink:href="#icon--thumbs-up" /></svg>
            <span>£30</span>
          </div>
        `;

        this.currentIds.push(selectedElement.id);
      }
    }

    setTimeout(() => {
      animationEvent.target.style.animationPlayState = "running";
    }, vagueOpacityCheck ? 200 : Math.random() * (19000 - 5000) + 5000);
  }
}

export default CommunitiesHeader;
