import Swiper, {Navigation} from 'swiper';

class TestimonialSwiper {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.swiper = null;
    this.initSwiper();
  }

  initSwiper() {
    this.swiper = new Swiper(this.element, {
      modules: [Navigation],
      allowTouchMove: true,
      allowClick: true,
      loop: true,
      spaceBetween: 50,
      slidesPerView: 'auto',
      centeredSlides: true,
      navigation: {
        prevEl: this.element.querySelector(".swiper-button.prev"),
        nextEl: this.element.querySelector(".swiper-button.next"),
      },
      on: {
        init: (swiper) => {
          setTimeout(() => {
            swiper.wrapperEl.style.height = `${swiper.el.offsetHeight}px`;
          }, 100);
        }
      }
    });
  }
}

export default TestimonialSwiper;
