import Swiper, {Navigation, Pagination} from "swiper";

class ReviewSlider {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.container = element;
    this.swiper = null;
    this.pagination = this.container.querySelector('.swiper-pagination');

    this.initSlider();
  }

  initSlider() {
    this.swiper = new Swiper(this.container, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      pagination: {
        el: this.pagination,
        clickable: true,
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
          slidesPerGroup: 3,
        },
        991: {
          slidesPerView: 2,
          spaceBetween: 30,
          slidesPerGroup: 2,
        },
      },
    });
  }
}

export default ReviewSlider;
