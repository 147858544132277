/* globals jQuery, wordpress_globals */
import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import './lib/lite-vimeo.js';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import FeatureSwiper from "./modules/FeatureSwiper";
import TabbedFeatures from "./modules/TabbedFeatures";
import CardSwiper from "./modules/CardSwiper";
import TestimonialSwiper from "./modules/TestimonialSwiper";
import NewsSwiper from "./modules/NewsSwiper";
import CTASection from "./modules/CTASection";
import AnchoredCTA from "./modules/AnchoredCTA";
import HeaderSection from "./modules/HeaderSection";
import Accordion from "./modules/Accordion";
import CommunitiesHeader from "./modules/CommunitiesHeader";
import PageNavigation from "./modules/PageNavigation";
import PriceTier from "./modules/PriceTier";
import FadeSwiper from "./modules/FadeSwiper";
import AnimateScroll from "./modules/AnimateScroll";
import ReviewSlider from "./modules/ReviewSlider";

(function ($) {
  $(window).load(function () {
    setTimeout(() => {
      $('.nf-form-cont').find("nf-field").contents().unwrap();
      $('.nf-form-cont').find("nf-fields-wrap").contents().unwrap();
      $('.nf-form-cont').find("nf-section").contents().unwrap();
      $('.nf-form-cont').find("nf-errors").contents().unwrap();
      $('.nf-form-cont').addClass("fields-fixed");
    }, 100);

    //Setup our on formSubmit Listener.
    jQuery(document).on('nfFormSubmitResponse', function(ev, response, id) {
      if(typeof window.gtag !== "undefined" && typeof response.id !== "undefined") {
        window.gtag('event', 'form_submission', {
      		'value': response.id,
      	});
      }
    });
  });
})(jQuery);

window.namespace = {
  init: function () {
    this.helpers.initSvg();
    this.cssVars.init();

    (document.querySelectorAll('.feature-swiper') ?? []).forEach((el) => new FeatureSwiper(el));
    (document.querySelectorAll('.fade-swiper') ?? []).forEach((el) => new FadeSwiper(el));
    (document.querySelectorAll('.tabs') ?? []).forEach((el) => new TabbedFeatures(el));
    (document.querySelectorAll('.card-swiper') ?? []).forEach(el => new CardSwiper(el));
    (document.querySelectorAll('.testimonial-swiper') ?? []).forEach(el => new TestimonialSwiper(el));
    (document.querySelectorAll('.news-swiper') ?? []).forEach(el => new NewsSwiper(el));
    (document.querySelectorAll('.section--cta') ?? []).forEach(el => new CTASection(el));
    (document.querySelectorAll('.anchored-cta') ?? []).forEach(el => new AnchoredCTA(el));
    (document.querySelectorAll('.accordion') ?? []).forEach(el => new Accordion(el));
    (document.querySelectorAll('.header--communities') ?? []).forEach(el => new CommunitiesHeader(el));
    (document.querySelectorAll('#site-navigation') ?? []).forEach(el => new PageNavigation(el));
    (document.querySelectorAll('.price-tier') ?? []).forEach(el => new PriceTier(el));
    (document.querySelectorAll('.site-review-slider') ?? []).forEach(el => new ReviewSlider(el));
    (document.querySelectorAll('.header') ?? []).forEach(el => new HeaderSection(el));

    Array.from(document.getElementsByClassName("line")).forEach(pathElement => {
      pathElement.setAttribute('style', 'stroke-dasharray:' + pathElement.getTotalLength() + ';stroke-dashoffset:' + pathElement.getTotalLength())
    })

    const animateScroll = new AnimateScroll();
    animateScroll.init();

    //Allow user to interrupt auto-scrolling
    $(document).bind('scroll mousedown wheel keyup', function (e) {
      if (e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
        $(document.scrollingElement).stop();
      }
    });
  },
  cssVars: {
    init: function () {
      this.setWindowHeight();
      this.setWindowWidth();
      this.setHeaderHeight();

      window.addEventListener("resize", () => {
        this.setWindowHeight();
        this.setWindowWidth();
        this.setHeaderHeight();
      });
    },
    setWindowHeight: function () {
      document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
    },
    setWindowWidth: function () {
      document.documentElement.style.setProperty('--window-width', `${window.innerHeight}px`);
    },
    setHeaderHeight: function () {
      const header = document.querySelector(".header");

      if (header) {
        document.documentElement.style.setProperty('--header-height', `${header.offsetHeight}px`);
      }
    }
  },
  helpers: {
    requireAll: function (r) {
      r.keys().forEach(r);
    },
    initSvg: function () {
      svgxhr({
        filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
      });
    }
  }
};

window.namespace.init();


if (typeof NodeList.prototype.forEach !== 'function') {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.namespace.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
