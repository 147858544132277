class PriceTier {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.toggle = this.element.querySelector(".price-tier__toggle");
    this.features = this.element.querySelector(".price-tier__features");

    if (this.features && this.toggle) {
      this.height = 0;

      this.initHeight();
      this.initEvents();
    }
  }

  initHeight() {
    const initialHeight = this.features.offsetHeight;

    this.features.style.display = "block";
    this.features.style.height = "auto";
    this.height = this.features.offsetHeight;

    this.features.style.height = `${initialHeight}px`;
  }

  initEvents() {
    if (this.toggle) {
      this.toggle.addEventListener("click", this.toggleEvent.bind(this));
    }
  }

  toggleEvent(event) {
    event.preventDefault();

    if (!this.element.classList.contains("open")) {
      this.element.classList.add("open");
      this.features.style.height = `${this.height}px`;
      this.toggle.innerText = "Hide Features -";
    } else {
      this.element.classList.remove("open");
      this.features.style.height = 0;
      this.toggle.innerText = "Show Features +";
    }
  }
}

export default PriceTier;
