/* eslint-disable max-len,no-plusplus,no-param-reassign */
import Scroll from '../lib/scroll.js';

class AnimateScroll extends Scroll {
  constructor() {
    super();
  }

  init() {
    (document.querySelectorAll('[href^="#"]') ?? []).forEach((link) => {
      link.addEventListener('click', this.initScroll.bind(this));
    });

    window.addEventListener('animate.scroll', (ev) => {
      let element = ev.detail;

      if (typeof element === 'undefined') {
        return false;
      }

      if (!(element instanceof HTMLElement)) {
        element = document.querySelector(element);
      }

      if (element !== null) {
        this.scroll(element, 1250);
      }

      return true;
    });
  }

  initScroll(ev) {
    if (typeof ev.target.getAttribute('href') === 'undefined') {
      console.warn('[AnimateScroll] Href attribute undefined');
      return;
    }
    if (ev.target.getAttribute('href') === '#') {
      return;
    }
    console.log(ev.target.getAttribute('href'));
    if (document.querySelector(ev.target.getAttribute('href')) === null) {
      console.warn(`[AnimateScroll] Referenced element does not exist - ${ev.target.getAttribute('href')}`);
      return;
    }

    ev.preventDefault();

    this.scroll(document.querySelector(ev.target.getAttribute('href')), 1250);
  }
}

export default AnimateScroll;
