import Swiper, {Autoplay} from "swiper";

class FeatureSwiper {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.swiper = null;
    this.initSwiper();
  }

  initSwiper() {
    this.swiper = new Swiper(this.element, {
      allowTouchMove: false,
      allowClick: false,
      modules: [Autoplay],
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 12000,
      },
    });
  }
}

export default FeatureSwiper;
