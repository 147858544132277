import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class HeaderSection {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.initialised = false;
    this.stage = this.element.querySelector('.header__stage');
    this.left = this.element.querySelector('.t-icon.left');
    this.right = this.element.querySelector('.t-icon.right');

    this.container = this.element.querySelector('.header__content');

    this.args = {
      scrollTrigger: {
        trigger: this.element,
        start: "-65 top",
        end: "+=1000",
        scrub: 1,
      }
    };

    if (!this.element.classList.contains(".header--article")) {
      if (window.outerWidth > 991) {
        this.initTimeline();
      }

      window.addEventListener("resize", () => {
        if (window.outerWidth > 991 && !this.initialised) {
          this.initTimeline();
        }
      });
    }
  }

  initTimeline() {
    const isFormHeader = this.element.classList.contains("header--form");

    gsap.timeline(this.args)
      .to(this.left, {
        keyframes: [
          {rotation: 79, yPercent: -10, xPercent: 6, duration: .3},
          {rotation: 84, yPercent: -15, xPercent: 9, duration: .4},
          {rotation: 90, yPercent: -20, xPercent: 12, duration: .6},
          {rotation: 101, yPercent: -4, xPercent: 12, duration: .7},
          {rotation: 107, yPercent: 8, xPercent: 12, duration: .8},
          {rotation: 107, yPercent: 25, xPercent: 12, duration: 1},
        ],
      });

    gsap.timeline(this.args)
      .to(this.right, {
        keyframes: [{rotation: -110, yPercent: 115, duration: 1}],
      });

    gsap.timeline(this.args)
      .to(this.stage, {
        keyframes: [{yPercent: isFormHeader ? -10 : -20, duration: 1}],
      });

    gsap.timeline(this.args).to(this.container, {
      y: (window.outerWidth < 768 || isFormHeader) ? "-160px" : "-240px"
    });

    gsap.timeline(this.args).to(this.element, {
      marginBottom: "-20px"
    });

    if (isFormHeader) {
      const nav = document.querySelector("#slim-navigation");

      gsap.timeline({
        ...this.args,
        scrollTrigger: {
          ...this.args.scrollTrigger,
          start: "0 top",
        }
      }).to(nav, {y: "-160px"});
    }

    this.initialised = true;
  }
}

export default HeaderSection;
