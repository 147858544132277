export default class AnchoredCTA {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError('Invalid element');
    }

    this.bar = element;
    this.scroll = document.scrollY;

    window.addEventListener('scroll', this.check.bind(this), {passive: true});
  }

  check() {
    if (window.outerWidth < 992)
      return false;

    const show = ((document.body.clientHeight / 3) - window.innerHeight) < window.scrollY;
    const footerHide = ((document.body.clientHeight - 200) - window.innerHeight) < window.scrollY;

    if ((show && !footerHide) && window.scrollY > 80) {
      this.bar.classList.add('active');
    } else {
      this.bar.classList.remove('active');
    }

    this.scroll = window.scrollY;
  }
}
