import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class CTASection {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.left = this.element.querySelector('.t-icon.left');
    this.right = this.element.querySelector('.t-icon.right');
    this.args = {
      scrollTrigger: {
        trigger: this.element,
        start: "top bottom",
        end: "+=1000",
        scrub: 1,
        // markers: true
      }
    }

    this.initTimeline();
  }

  initTimeline() {
    gsap.timeline(this.args)
      .to(this.left, {
        keyframes: [
          {rotation: 79, yPercent: -10, xPercent: 6, duration: .3},
          {rotation: 84, yPercent: -15, xPercent: 9, duration: .4},
          {rotation: 90, yPercent: -20, xPercent: 12, duration: .6},
          {rotation: 101, yPercent: -4, xPercent: 12, duration: .7},
          {rotation: 107, yPercent: 8, xPercent: 12, duration: .8},
          {rotation: 107, yPercent: 25, xPercent: 12, duration: 1},
        ],
      });


    gsap.timeline(this.args)
      .to(this.right, {
        keyframes: [
          {rotation: -110, yPercent: 115, duration: 1},
        ],
      });
  }
}

export default CTASection;
